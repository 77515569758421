import React from "react"

const Twitter = props => (
  <svg viewBox="0 0 16 14" {...props}>
    <defs>
      <path
        d="M14.3553 3.83854C14.3655 3.98066 14.3655 4.12282 14.3655 4.26494C14.3655 8.59994 11.066 13.5949 5.03553 13.5949C3.17766 13.5949 1.45178 13.0568 0 12.1228C0.263969 12.1533 0.51775 12.1634 0.791875 12.1634C2.32484 12.1634 3.73603 11.6457 4.86294 10.7624C3.42131 10.7319 2.21319 9.78779 1.79694 8.48829C2 8.51873 2.20303 8.53904 2.41625 8.53904C2.71066 8.53904 3.00509 8.49841 3.27919 8.42738C1.77666 8.12279 0.649719 6.80301 0.649719 5.2091V5.16851C1.08625 5.41216 1.59391 5.56444 2.13194 5.58473C1.24869 4.99588 0.670031 3.99082 0.670031 2.85376C0.670031 2.24463 0.832437 1.68626 1.11672 1.19894C2.73094 3.18879 5.15734 4.48826 7.87812 4.63041C7.82737 4.38676 7.79691 4.13298 7.79691 3.87916C7.79691 2.07204 9.25884 0.599976 11.0761 0.599976C12.0202 0.599976 12.873 0.995913 13.472 1.63551C14.2131 1.49338 14.9238 1.21926 15.5532 0.843632C15.3096 1.60507 14.7918 2.24466 14.1116 2.65073C14.7715 2.57969 15.4111 2.39691 15.9999 2.14313C15.5533 2.79285 14.9949 3.37151 14.3553 3.83854Z"
        id="twitter-icon"
      />
    </defs>
    <use xlinkHref="#twitter-icon" transform="translate(-.75)" />
  </svg>
)

export default Twitter
