import React from 'react';

const Signup = props => (
  <svg width="21" height="20" viewBox="0 0 21 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.644531 3C0.644531 1.34315 1.98768 0 3.64453 0H16.6445C18.3014 0 19.6445 1.34315 19.6445 3V7.09091C19.6445 7.64319 19.1968 8.09091 18.6445 8.09091C18.0922 8.09091 17.6445 7.64319 17.6445 7.09091V3C17.6445 2.44772 17.1968 2 16.6445 2H3.64453C3.09225 2 2.64453 2.44772 2.64453 3V13C2.64453 13.5523 3.09225 14 3.64453 14H4.64453C5.19682 14 5.64453 14.4477 5.64453 15C5.64453 15.5523 5.19682 16 4.64453 16H3.64453C1.98768 16 0.644531 14.6569 0.644531 13V3Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.3516 14.2929C20.7422 14.6834 20.7422 15.3166 20.3516 15.7071L16.3516 19.7071C15.9611 20.0976 15.3279 20.0976 14.9374 19.7071C14.5469 19.3166 14.5469 18.6834 14.9374 18.2929L17.2303 16H9.64453C9.09225 16 8.64453 15.5523 8.64453 15C8.64453 14.4477 9.09225 14 9.64453 14L17.2303 14L14.9374 11.7071C14.5469 11.3166 14.5469 10.6834 14.9374 10.2929C15.3279 9.90237 15.9611 9.90237 16.3516 10.2929L20.3516 14.2929Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.77557 0H16.5135C18.2427 0 19.6445 1.40182 19.6445 3.13104C19.6445 4.54957 18.6909 5.79087 17.3202 6.15637L11.1752 7.79505C10.4999 7.97513 9.78918 7.97513 9.11388 7.79505L2.96882 6.15637C1.5982 5.79087 0.644531 4.54957 0.644531 3.13104C0.644531 1.40182 2.04635 0 3.77557 0ZM3.77557 2C3.15092 2 2.64453 2.50639 2.64453 3.13104C2.64453 3.64346 2.98903 4.09187 3.48415 4.2239L9.62921 5.86258C9.96686 5.95262 10.3222 5.95262 10.6599 5.86258L16.8049 4.2239C17.3 4.09187 17.6445 3.64346 17.6445 3.13104C17.6445 2.50639 17.1381 2 16.5135 2H3.77557Z"/>
  </svg>
);

export default Signup;
