import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import classNames from 'classnames';
import { Form, SubmitButton, Notice } from '../Form';
import Modal from '../Modal';
import { Row, Column } from '../Grid';
import schema from './schema';
import Cookie from 'js-cookie';
import moment from 'moment';
import './styles.scss';

const Subscribe = ({ trigger, className, ...other }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            programName
            partnerPrivacyUrl
            subscribeFormId
          }
        }
      }
    `
  );

  const ineligibleMessage =
    'You have to be 13 years of age or older to sign up for updates.';

  const [isComplete, setIsComplete] = useState(false);
  const [errors, setErrors] = useState({});
  const errorsRef = React.createRef();
  const cookie = Cookie.get('signup-ineligible');
  let isEligible = typeof cookie === 'undefined' ? true : false;
  const ageErrors = [];
  if (!isEligible) {
    ageErrors.push(ineligibleMessage);
  }

  const [fieldValues, setFieldValues] = useState(
    Object.assign(
      {},
      ...Object.entries(schema).map(([k, v]) => ({
        [k]: 'value' in v ? v.value : 'checked' in v ? v.checked : null,
      }))
    )
  );

  if (typeof schema.subscribeToPartner.label === 'function') {
    schema.subscribeToPartner.label = schema.subscribeToPartner.label(
      site.siteMetadata.programName,
      site.siteMetadata.partnerPrivacyUrl
    );
  }

  const handleFieldChange = (name, value) => {
    setFieldValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!validateFields()) return;

    const fieldValuesBirthdayInfoRemoved = Object.assign({}, fieldValues);
    delete fieldValuesBirthdayInfoRemoved.day;
    delete fieldValuesBirthdayInfoRemoved.month;
    delete fieldValuesBirthdayInfoRemoved.year;

    const formId = site.siteMetadata.subscribeFormId;

    if (formId) {
      axios.post(
        `https://cepdata.discoveryeducation.com/api/v2/form`,
        {
          property_id: formId,
          fields: fieldValuesBirthdayInfoRemoved,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    }

    setIsComplete(true);
  };

  const renderValue = (name) => {
    const selectedOption = schema[name].options;
    return selectedOption[fieldValues[name]];
  };

  const birthdayValidation = () => {
    const ageErrors = [];

    const birthday = moment(
      `${renderValue('year')}/${renderValue('month')}`,
      'YYYY/MMM MMMM'
    );

    const age = moment().diff(birthday, 'years');

    const birthdayFieldsFilled = renderValue('month') && renderValue('year');

    if (birthdayFieldsFilled && age < 13) {
      Cookie.set('signup-ineligible', 1, { expires: 1 });
      isEligible = false;
      ageErrors.push(ineligibleMessage);
    }

    if (!birthdayFieldsFilled || !birthday.isValid()) {
      ageErrors.push('Please provide your date of birth.');
    }

    if (ageErrors.length) {
      return ageErrors;
    }
  };

  const getErrorMessage = (name, value) => {
    const { label, required, validation } = schema[name];

    if (
      required &&
      ((typeof schema[name].value && value === '') ||
        (Array.isArray(schema[name].value) && value.length === 0) ||
        (schema[name].value.constructor === Object &&
          Object.entries(value).length === 0))
    ) {
      return `${label} is required`;
    } else if (validation && !validation.test(value)) {
      return validation.message;
    }

    return false;
  };

  const validateFields = () => {
    const errors = {};

    Object.entries(fieldValues).forEach(([name, value]) => {
      const error = getErrorMessage(name, value);

      if (error) {
        errors[name] = error;
      }
    });

    const hasBirthdayError = birthdayValidation();

    if (hasBirthdayError) {
      errors.birthdate = hasBirthdayError;
    }

    setErrors(errors);

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  const renderThankYou = () => (
    <>
      <h2 className="h3">Thank you for your interest in the program.</h2>
      <p>You will be notified of any updates and annoucements.</p>
    </>
  );

  const renderField = (name) => {
    if (!isEligible) return;

    const { component, validation, ...attributes } = schema[name];
    const FieldComponent = component;

    if ('value' in attributes) {
      attributes.value = fieldValues[name];
    } else if ('checked' in attributes) {
      attributes.checked = fieldValues[name];
    }

    return (
      <FieldComponent
        name={name}
        onChange={handleFieldChange}
        error={errors[name] ? errors[name] : undefined}
        {...attributes}
      />
    );
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      {isEligible && (
        <>
          <h2>Sign up for Sustainable Futures updates</h2>
          <p>
            Sign up for the mailing list to get updates from the Sustainable
            Futures on new content, events, leadership panels and more.
          </p>
        </>
      )}
      {renderField('email')}
      {renderField('role')}
      <Row>
        <Column size={6}>
          {isEligible && <h3 className="subscribe__heading">Grade Band</h3>}
          {renderField('inGradesKto3')}
          {renderField('inGrades4to5')}
          {renderField('inGrades6to8')}
          {renderField('inGrades9to12')}
        </Column>
        <Column size={6}>
          {isEligible && <h3 className="subscribe__heading">Interests</h3>}
          {renderField('interestedInEducation')}
          {renderField('interestedInSTEM')}
          {renderField('interestedInLanguageArts')}
          {renderField('interestedInVirtualFieldTrips')}
          {renderField('interestedInSweepstakesAndChallenges')}
        </Column>
        {isEligible && (
          <h3 className="subscribe__heading subscribe__heading--birthday">
            Birthday
          </h3>
        )}
        <div className="subscribe__birthday-fields">
          {renderField('month')}
          {renderField('year')}
        </div>
      </Row>
      {renderField('subscribeToDE')}
      {renderField('subscribeToPartner')}
      {Object.keys(errors).length > 0 && !ageErrors.length && (
        <Notice error>Please review the form for errors.</Notice>
      )}
      {isEligible && <SubmitButton>Submit</SubmitButton>}
    </Form>
  );

  return (
    <Modal trigger={trigger} title="Sign Up for Updates">
      <div className={classNames('subscribe', className)} {...other}>
        {/* <h2 className="mb-2">Sign Up For Updates</h2> */}
        {ageErrors.length > 0 && (
          <ul className="subscribe__errors" ref={errorsRef}>
            {ageErrors.map((error, i) =>
              error !== undefined ? <li key={i}>{error}</li> : ''
            )}
          </ul>
        )}
        {isComplete ? renderThankYou() : renderForm()}
      </div>
    </Modal>
  );
};

Subscribe.propTypes = {
  /** The element that triggers the modal to open when clicked */
  trigger: PropTypes.node.isRequired,
  /** A custom class name */
  className: PropTypes.string,
};

export default Subscribe;
