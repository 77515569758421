import React from "react"

const Download = props => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="M8.99984 0.666626C9.46008 0.666626 9.83317 1.03972 9.83317 1.49996V7.82145L11.7439 5.9107C12.0694 5.58527 12.597 5.58527 12.9224 5.9107C13.2479 6.23614 13.2479 6.76378 12.9224 7.08922L9.58909 10.4225C9.43281 10.5788 9.22085 10.6666 8.99984 10.6666C8.77882 10.6666 8.56686 10.5788 8.41058 10.4225L5.07725 7.08921C4.75181 6.76378 4.75181 6.23614 5.07725 5.9107C5.40269 5.58527 5.93032 5.58527 6.25576 5.9107L8.1665 7.82145V1.49996C8.1665 1.03972 8.5396 0.666626 8.99984 0.666626ZM1.49984 8.16663C1.96007 8.16663 2.33317 8.53972 2.33317 8.99996V14.8333C2.33317 15.2935 2.70627 15.6666 3.1665 15.6666H14.8332C15.2934 15.6666 15.6665 15.2935 15.6665 14.8333V8.99996C15.6665 8.53972 16.0396 8.16663 16.4998 8.16663C16.9601 8.16663 17.3332 8.53972 17.3332 8.99996V14.8333C17.3332 16.214 16.2139 17.3333 14.8332 17.3333H3.1665C1.78579 17.3333 0.666504 16.214 0.666504 14.8333V8.99996C0.666504 8.53972 1.0396 8.16663 1.49984 8.16663Z"></path>
    <use xlinkHref="#download-icon" transform="translate(-.35)" />
  </svg>
)

export default Download
