import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const validTypes = ['tranelogin', 'tranelogout'];

const AuthButton = ({ type, ready, buttonText, classname }) => {
  const isValid = validTypes.indexOf(type.toLowerCase()) >= 0;

  const renderButton = () => {
    return (
      <a
        className={classNames(classname ? classname : 'button')}
        href={`${
          type.toLowerCase() === 'tranelogout' ? '/user/logout' : '/samllogin'
        }`}
      >
        {type.toLowerCase() === 'tranelogout' ? 'Logout' : buttonText}
      </a>
    );
  };

  return <>{isValid && ready && renderButton()}</>;
};

AuthButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AuthButton;
